/** @jsx jsx */
import { jsx, Styled, Layout, Main, Container } from "theme-ui"
import { Global } from "@emotion/core"

import SkipLink from "./skip-link"
import Header from "./header"
import Footer from "./footer"
import Head from "./head"
import SEO from './seo'
import { Image } from 'rebass'

export default props => {
  return (
    <Styled.root>
      <Head {...props} />
      <Global
        styles={{
          "*": {
            boxSizing: "border-box",
          },
          body: {
            margin: 0,
          },
        }}
      />
      <SkipLink>Skip to content</SkipLink>
      <Layout>
        {/* <SEO /> */}
        <Header />
        {props.home ? <Image src='1.jpg' /> : null}
        <Main>
          <Container
            sx={{
              pt: 0,
              pb: 5,
              px: 3,
            }}
          >
            <div
              sx={{
                display: ["block", "flex"],
                mx: props.fullwidth ? 0 : -3,
              }}
            >
              <div
                id="content"
                sx={{
                  width: "100%",
                  minWidth: 0,
                  px: props.fullwidth ? 0 : 3,
                }}
              >
                {props.children}
              </div>
            </div>
          </Container>
        </Main>
        <Footer />
      </Layout>
    </Styled.root>
  )
}
