/** @jsx jsx */
import { jsx, Header, Container, Flex, useColorMode } from "theme-ui"
import { Text } from 'rebass'
import NavLink from "./nav-link"
import Button from "./button"
import Logo from "./logo"

const modes = ["light", "dark"]

const Txt = ({ children }) => (
  <Text sx={{ py: [20, 32], fontSize: [1, '15px'] }}>{children}</Text>
)

export default ({ menuOpen, setMenuOpen, nav }) => {
  const [mode, setMode] = useColorMode()

  const cycleMode = e => {
    const i = modes.indexOf(mode)
    const next = modes[(i + 1) % modes.length]
    setMode(next)
  }

  return (
    <Header>
      <Container>
        <Flex sx={{ height: '90px', justifyContent: "space-between" }}>
          <Flex sx={{ width: ['150px', '200px'], ml: -4 }}>
            <NavLink to="/">
              <Logo src='LTS-LOGO.png' />
            </NavLink>
          </Flex>
          <Flex>
            <NavLink to="/#partners" >
              <Txt>Partners</Txt>
            </NavLink>
            <NavLink to="/#about-us" >
              <Txt>About</Txt>
            </NavLink>
            <NavLink to="/#contact-us">
              <Txt>Contact</Txt>
            </NavLink>
          </Flex>
        </Flex>
      </Container>
    </Header>
  )
}




/**
 * <Button
              sx={{
                ml: 2,
              }}
              onClick={cycleMode}
            >
              {mode}
            </Button>
 */
