/** @jsx jsx */
import { Footer, Container, jsx } from "theme-ui"
import { Flex, Box, Text } from 'rebass'
import NavLink from './nav-link'

export default props => (

	<Footer sx={{ backgroundColor: 'muted' }}>
		<Container>
			<Flex sx={{ py: 3, /**borderTop: '1px solid grey'*/ }}>
				<NavLink to='/'>
					Home
				</NavLink>
				<NavLink to='/#partners'>
					Partners
				</NavLink>
				<NavLink to='/#about-us'>
					About
				</NavLink>
				<NavLink to='/#contact-us'>
					Contact
				</NavLink>
				<Box sx={{ mx: 'auto' }} />
				<Text sx={{ p: 2, fontSize: 1 }}>© 2021 Lichtenburg Technical Services</Text>
			</Flex>
		</Container>
	</Footer>
)